import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { NgxSelectModule } from 'ngx-select-ex';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FxCoreModule, Constants } from 'fx-core';
import { UserPreferenceService } from '../services/userpreference.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password';
import { UserService } from './services/user.service';
import { ConferenceService } from './services/conference.service';
import { WCHomeComponent } from './wchome/wchome.component';
import { GuestConferenceComponent } from './guestconference/guestconference.component';
import { RvsLoginComponent } from './rvs-login/rvs-login.component';

const routes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'forgotpassword', component: ForgotPasswordComponent },
    { path: 'wchome', component: WCHomeComponent },
    { path: 'guestconference', component: GuestConferenceComponent }
];
@NgModule({
    imports: [RouterModule, FxCoreModule,  RouterModule.forChild(routes)],
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        WCHomeComponent,
        GuestConferenceComponent,
        RvsLoginComponent
    ],
    exports: [
        SharedModule,
        LoginComponent,
        ForgotPasswordComponent,
        WCHomeComponent,
        RouterModule
    ],
    providers: [
        UserPreferenceService,
        UserService,
        ConferenceService
    ],
    entryComponents: [
    ],
})

export class SecurityModule { }